import React from 'react';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button.jsx';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { withPrefix } from 'gatsby';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import PolicyCard from 'components/PolicyCard/PolicyCard';
import Snackbar from 'components/Snackbar/Snackbar';
import { STAGES, DOC_TYPES, ICON_LIST } from 'util/SelfService';
import { browserDocument } from 'util/localStorageHelper';

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

class PolicyDetails extends React.Component {
  state = { 
    mailSent: false,
    downloadFailure:false,
    downloadFailureErrorMsg:''
   };

   downloadFile = (mailId, policyNo, polSysID, doc_name,dataSourceFrom,sessionPolicyToken) => {
    let downloadAPI = `${CUSTOMER_PORTAL_API_URL}/cp/policy/user/updated/download/policy/documents`
    
    let bodyJSON
    // ({
    //   documentType: 'POLICY_DOC',
    //   policyNumber: policyNo,
    //   policySysId: polSysID,
    //   uwType: dataSourceFrom,     
    // })

    if (dataSourceFrom=='BANCS')
    {
      bodyJSON = JSON.stringify({
      documentType: 'POLICY_DOC',
      policyNumber: policyNo,
      //policySysId: '',
      uwType: dataSourceFrom,     
    })
   }
   else{
     bodyJSON = JSON.stringify({
      documentType: 'POLICY_DOC',
      policyNumber: policyNo,
      policySysId: polSysID,
      uwType: dataSourceFrom,     
    })
   }


    // api/portal/policy/user/0/download/documents?
    // ${dataSourceFrom =='BANCS' ?`PolicyNumber=${encodeURIComponent(
    //   policyNo
    // )}&uwType=${dataSourceFrom}` : `PolicySysId=${encodeURIComponent(
    //   polSysID
    // )}`}&DocumentType=POLICY_DOC`;
    let mailAPI
    if(dataSourceFrom == 'BANCS'){
    mailAPI = `${CUSTOMER_PORTAL_API_URL}/cp/policy/user/send/documents?${dataSourceFrom =='BANCS' ?`uwType=${dataSourceFrom}` : `uwType=PREMIA`}&policyno=${encodeURIComponent(policyNo)}`;
    }
    else{
    mailAPI = `${CUSTOMER_PORTAL_API_URL}/cp/policy/user/send/documents?${dataSourceFrom =='BANCS' ?`uwType=${dataSourceFrom}` : `uwType=PREMIA`}&policysysid=${encodeURIComponent(polSysID)}&policyno=${encodeURIComponent(policyNo)}`;
    }
    switch (this.props.currentStage) {
      case STAGES.DOWNLOAD_DOCS:
        fetch(downloadAPI, {
          method: `POST`,
          responseType: `blob`,
          headers: {
            'content-type': 'application/json',
            clientId: `WTNWemRHOXRaWEl0Y0c5eWRHRnM=`,
            Authorization: `Bearer ${sessionPolicyToken ? sessionPolicyToken : this.props.policyToken}`
          },
          body: bodyJSON
        })  
          .then(res => res.blob())
          .then(res => {
            if (
              res.type!='application/pdf'
            ) {
              this.setState({
                downloadFailure: true,
                downloadFailureErrorMsg: 'No Documents Found',
              });
            } else{
              const fileURL = URL.createObjectURL(res);
              const elem = browserDocument.createElement(`a`);
              browserDocument.body.appendChild(elem);
              elem.style = `display: none`;
              elem.setAttribute(`download`, doc_name);
              elem.setAttribute(`href`, fileURL);
              elem.click();
              this.setState({
                downloadFailure: false,
                downloadFailureErrorMsg: '',
              });
            } 
          });
        break;
      case STAGES.SEND_EMAIL:
        this.setState({ mailSent: false });
        fetch(mailAPI, {
          method: `GET`,
          headers: {
            clientId: `WEB`,
            Authorization: `Bearer ${sessionPolicyToken ? sessionPolicyToken : this.props.policyToken}`
          }
        }).then(res => {
          if (res.status === 200) {
            this.setState({ mailSent: true });
          }
        });
        break;
    }
  };

  render() {
    const { policy, classes } = this.props;
    return (
      <GridContainer
        md={12}
        sm={12}
        className={classNames(classes.mlAuto, classes.mrAuto)}>
        <GridContainer md={12} sm={12}>
          <GridItem md={6} sm={6}>
            <PolicyCard policyDetails={policy} classes={classes} />
          </GridItem>
          <GridItem className={classes.actionButton} md={6} sm={6}>
            <GridContainer md={12}>
              <GridItem className={classes.textCenter}>
                <Button
                  color='white'
                  round
                  justIcon
                  target={`_blank`}
                  className={`${classes.downloadButton}`}
                  onClick={() =>
                    this.downloadFile(
                      policy.mailIdEncrypted,
                      policy.policyNumberEncrypted,
                      policy.polSysIDEncrypted,
                      policy.dataSourceFrom=='BANCS' ? policy.body : `${policy.polSysID}_${DOC_TYPES.POLICY_SCHEDULE}`,
                      policy.dataSourceFrom,
                      policy.sessionPolicyToken,
                    )
                  }>
                  <ReactSVG
                    src={withPrefix(ICON_LIST[this.props.currentStage])}
                  />
                </Button>
                <span
                  style={{
                    textAlign: `center`,
                    display: `block`,
                    fontWeight: 400,
                    overflowWrap: `initial`
                  }}>
                  Policy Schedule
                </span>
                {this.state.downloadFailure && (
                        <span
                        style={{
                          textAlign: `center`,
                          display: `block`,
                          fontWeight: 400,
                          overflowWrap: `initial`,
                          color :'red'
                        }}>
                        {this.state.downloadFailureErrorMsg}
                        </span>
                )}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        {this.state.mailSent && (
          <GridItem
            md={9}
            className={classNames(classes.mlAuto, classes.mrAuto)}>
            <Snackbar
              duration={3000}
              open={true}
              status='success'
              message='Policy schedule sent to your registred email id'
              style={{ position: `absolute`, top: `0px` }}
              flag={() => {}}
            />
          </GridItem>
        )}
      </GridContainer>
    );
  }
}

PolicyDetails.propTypes = {
  classes: PropTypes.object,
  policy: PropTypes.object,
  currentStage: PropTypes.string,
  iconURL: PropTypes.string,
  policyToken: PropTypes.string,
};

export default PolicyDetails;