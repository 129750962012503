import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
import PropTypes from "prop-types";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import downloadsStyle from "ps-assets/jss/downloadsStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { Helmet } from "react-helmet";
import SelfService from "../ps-components/Header/SelfService";

const displayOrder = [
  "Brochures",
  "Prospectus",
  "Proposal",
  "Policy Clause",
  "Other Excluded Expenses",
  "Coverage for Modern Treatment",
  "Rationale For Revision In Price",
  "Preauthorisation Form",
  "Proforma Service Request Form",
  "AML KYC Reference Documents"
];

const Downloads = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var Alldata = data.allStrapiDocuments.edges;
  let CategoryObj = {};
  if (Object.keys(CategoryObj).length > 0) {
    CategoryObj = {};
  }
  for (let nodeObj of Alldata) {
    let node = nodeObj.node;
    let existingCategories = Object.keys(CategoryObj);
    let category = node.Category;
    if (node.insuranceplan !== null && node.insuranceplan.isWithdrawn != true) {
      let insuranceSubCategory = node.insuranceplan.subCategory;
      let linkObj = node.link;
      if (existingCategories.includes(category)) {
        let insuranceSubCategoryObj = CategoryObj[category];
        if (
          Object.keys(insuranceSubCategoryObj).includes(insuranceSubCategory)
        ) {
          for (let j in insuranceSubCategoryObj[insuranceSubCategory]) {
            let currentLinkObj =
              insuranceSubCategoryObj[insuranceSubCategory][parseInt(j)];
            let currentId = currentLinkObj.id;
            if (currentId === linkObj[0].id) {
              break;
            } else if (
              parseInt(j) ===
              insuranceSubCategoryObj[insuranceSubCategory].length - 1
            ) {
              insuranceSubCategoryObj[insuranceSubCategory].push(linkObj[0]);
            }
          }
        } else {
          insuranceSubCategoryObj[insuranceSubCategory] = linkObj;
        }
      } else {
        let insuranceSubCategoryObj = {};
        insuranceSubCategoryObj[insuranceSubCategory] = linkObj;
        CategoryObj[category] = insuranceSubCategoryObj;
      }
    }
  }

  let AccordionArray = Object.keys(CategoryObj).map(category => {
    let formatted_category = category
      .split(`_`)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(` `);

    if (category === `aml_kyc_reference_documents`) {
      let formatted_categoryArr = formatted_category.split(` `);
      formatted_categoryArr[0] = formatted_categoryArr[0].toUpperCase();
      formatted_categoryArr[1] = formatted_categoryArr[1].toUpperCase();
      formatted_category = formatted_categoryArr.join(` `);
      
    }

    if(category === `modern_treatment`){
      formatted_category = `Coverage for Modern Treatment`;
    }

    let insuranceSubCategoryObj = CategoryObj[category];
    let accordionContents = Object.keys(insuranceSubCategoryObj).map(
      (insuranceSubCategory, i) => {
        let insuranceSubCategories = [
          `health`,
          `speciality_products`,
          `accident`,
          `combi_product`,
          `overseas_travel`,
          `domestic_travel`,
          `pos_products`,
          `package_products`,
          `global`
        ];
        let displaySubCategories = [
          `Health`,
          `Health - Speciality Products`,
          `Accident`,
          `Combi Product`,
          `Overseas Travel`,
          `Domestic Travel`,
          `POS Products`,
          `Package Products`,
          ``
        ];
        let displaySubCategory =
          displaySubCategories[
            insuranceSubCategories.indexOf(insuranceSubCategory)
          ];


          if (insuranceSubCategory === `global` && category == 'aml_kyc_reference_documents'){
            let linkObjArr = insuranceSubCategoryObj[insuranceSubCategory];
            let linkFirstlist = [];
            linkObjArr.map((linkObj, index) => {
            linkFirstlist.push(
              <div style={{ textAlign: `left` }} key={`linkObj-${index}`}>
                <span>
                  <a
                    href={linkObj.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: `grey` }}
                  >
                    {linkObj.title}
                  </a>
                </span>
              </div>
            );
            })
            return(
              <GridContainer>
              <GridItem
                                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textLeft} `}
                                xs={12}
                                sm={12}
                                md={6}
                              >
                                {linkFirstlist}
                              </GridItem>
              </GridContainer>
              )
          }


        else if (insuranceSubCategory === `global`) {
          let linkObj = insuranceSubCategoryObj[insuranceSubCategory][0];
          return (
            <div style={{ textAlign: `left` }} key={`global-${i}`}>
              <a
                href={linkObj.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: `grey` }}
              >
                {linkObj.title}
              </a>
            </div>
          ); 
        }


   

        else if (insuranceSubCategory === `health`) {
          let linkObjArr = insuranceSubCategoryObj[insuranceSubCategory];
          let linkFirstlist = [],
            linkSecondlist = [];
          linkObjArr.map((linkObj, index) => {
            let linkParts = linkObj.title.split(`|`);
            let displayTitle;
            if (linkParts.length > 1) {
              displayTitle = linkParts.map((linkPart, i) => {
                if (i === linkObj.title.split(`|`).length - 1) {
                  return (
                    <span style={{ color: `#35488a` }} key={`linkObj-${i}`}>
                      {linkPart}
                    </span>
                  );
                } else {
                  return (
                    <span style={{ color: `grey` }} key={`linkObj-${i}`}>
                      {linkPart}
                    </span>
                  );
                }
              });
            } else {
              displayTitle = (
                <span style={{ color: `grey` }} key={`linkObj-${index}`}>
                  {linkObj.title}
                </span>
              );
            }
            if (index % 2 === 0) {
              linkFirstlist.push(
                <div style={{ textAlign: `left` }} key={`linkObj-${index}`}>
                  <span>
                    <a
                      href={linkObj.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {displayTitle}
                    </a>
                  </span>
                </div>
              );
            } else {
              linkSecondlist.push(
                <div style={{ textAlign: `left` }} key={`linkObj-${index}`}>
                  <span>
                    <a
                      href={linkObj.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: `grey` }}
                    >
                      {displayTitle}
                    </a>
                  </span>
                </div>
              );
            }
          });
          return (
            <div key={`health-${i}`}>
              <GridContainer>
                <GridItem md={12}>
                  <h4
                    style={{
                      textAlign: `left`,
                      fontWeight: `bold`,
                      marginTop: 30
                    }}
                  >
                    {displaySubCategory}
                  </h4>
                </GridItem>
                {category == 'rationale_for_revision_in_price' ? (
                <GridItem
                  className={`${classes.mrAuto}`}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  
                  {linkFirstlist}
                  {linkSecondlist}
                </GridItem>
                ) : (
                  <>
                  <GridItem
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {linkFirstlist}
                </GridItem>
                <GridItem
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {linkSecondlist}
                </GridItem>
                </>
                )}
              </GridContainer>
            </div>
          );
        } else {
          let linkObjArr = insuranceSubCategoryObj[insuranceSubCategory];

          let linklist = linkObjArr.map((linkObj, idx) => {
            let linkParts = linkObj.title.split(`|`);
            let displayTitle;
            if (linkParts.length > 1) {
              displayTitle = linkParts.map((linkPart, i) => {
                if (i === linkObj.title.split(`|`).length - 1) {
                  return (
                    <span style={{ color: `#35488a` }} key={`linkObj1-${i}`}>
                      {linkPart}
                    </span>
                  );
                } else {
                  return (
                    <span style={{ color: `grey` }} key={`linkObj1-${i}`}>
                      {linkPart}
                    </span>
                  );
                }
              });
            } else {
              displayTitle = (
                <span style={{ color: `grey` }} key={`linkObj1-${idx}`}>
                  {linkObj.title}
                </span>
              );
            }
            return (
              <div key={`linkObj1-${idx}`} style={{ textAlign: `left` }}>
                <span>
                  <a
                    href={linkObj.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: `grey` }}
                  >
                    {displayTitle}
                  </a>
                </span>
              </div>
            );
          });
          return (
            <div key={`generic-${i}`}>
              <h4
                style={{
                  textAlign: `left`,
                  fontWeight: `bold`,
                  marginTop: 30
                }}
              >
                {displaySubCategory}
              </h4>
              {linklist}
            </div>
          );
        }
      }
    );

    return {
      title: <span style={{ fontSize: 20 }}>{formatted_category}</span>,
      content: accordionContents
    };
  });
  AccordionArray.sort((a,b) => displayOrder.indexOf(a.title.props.children) - displayOrder.indexOf(b.title.props.children))
  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Download Health Insurance Brochures | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link rel="canonical" href="https://www.starhealth.in/Download" />
          <meta
            name="title"
            content="Download Health Insurance Brochures | StarHealth.in"
          />
          <meta
            name="twitter:title"
            content="Download Health Insurance Brochures | StarHealth.in"
          />
          <meta
            name="description"
            content="Download Health Insurance Brochures which related to all type of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products."
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Download Health Insurance Brochures | StarHealth.in" />
          <meta property="og:description" content="Download Health Insurance Brochures which related to all type of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products." />
          <meta property="og:url" content="https://www.starhealth.in/Download"/>
          <meta name="Download Health Insurance Brochures | StarHealth.in" />
          <meta name="twitter:description" content="Download Health Insurance Brochures which related to all type of Health Insurance Policy, Accident Insurance, Travel Insurance and Combi Products." />
          <meta property="twitter:url" content="https://www.starhealth.in/Download" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer
              className={`${classes.container} ${classes.textCenter}`}
            >
              <GridItem
                md={12}
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
              />
              <GridItem
                className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter}`}
                xs={12}
                sm={12}
                md={10}
                style={{ marginBottom: 50, paddingTop: 70 }}
              >
                <SelfService  customDescription={true} />
                <Accordion collapses={AccordionArray} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
Downloads.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(downloadsStyle)(Downloads);

export const downloadsQuery = graphql`
  query Downloads {
    allStrapiDocuments(
      filter: {
        Category: {
          in: [
            "brochures"
            "proposal"
            "policy_clause"
            "other_excluded_expenses"
            "preauthorisation_form"
            "proforma_service_request_form"
            "prospectus"
            "aml_kyc_reference_documents",
            "modern_treatment",
            "rationale_for_revision_in_price"
            
          ]
        }
      }
    ) {
      edges {
        node {
          strapiId
          Title
          Category
          insuranceplan {
            id
            category
            subCategory
            isWithdrawn
          }
          link {
            id
            title
            url
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "downloads" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
