import React from "react";
import { withPrefix } from "gatsby";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import policyCardStyles from "assets/jss/material-kit-pro-react/components/policyCardStyles.jsx";
import { primaryColor, whiteColor } from "assets/jss/material-kit-pro-react";

const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function formatMonth(date) {
  let requiredDateFormat = ``;
  if (date.indexOf("/") !== -1) {
    const dateSplit = date.split("/");
    dateSplit[1] = monthNames[parseInt(dateSplit[1]) - 1];
    requiredDateFormat = dateSplit.join("-");
  } else if (date.indexOf("-") !== -1) {
    const dateSplit = date.split("-");
    dateSplit[1] = monthNames[parseInt(dateSplit[1]) - 1];
    requiredDateFormat = dateSplit.join("-");
  }
  return requiredDateFormat;
}

class PolicyCard extends React.Component {
  state = {};

  applyCommasForAmount = (amount) => {
    amount = amount.toString();
    if (amount.length > 3) {
      const newAmount = amount.split("");
      newAmount.splice(-3, 0, ",");
      amount = newAmount.join("");
      if (amount.length > 6) {
        const newAmount = amount.split("");
        newAmount.splice(-6, 0, ",");
        amount = newAmount.join("");
      }
      return amount;
    }
    return amount;
  };

  render() {
    const { classes } = this.props;
    const {
      createdDate,
      expiryDate,
      sumInsured,
      headerTitle,
      emiPolicy,
      peopleCovered,
      body,
    } = this.props.policyDetails;
    let { headerDescription } = this.props.policyDetails;
    const enableRotate = true;
    const viewMoreDetails = false;
    headerDescription = (headerDescription.startsWith("MED") || headerDescription.startsWith("H") || headerDescription.startsWith("Y")) 
      ? "Health Plan"
      : headerDescription.startsWith("ACC")
      ? "Accident Plan"
      : headerDescription.startsWith("OMP")
      ? "Travel Plan"
      : corporatePlan
      ? "Corporate Plan"
      : "Health Plan";
    let STATIC_ASSET_BASE_PATH = "/";
    let starInverted = "static/star_inverted.png";
    return (
      <GridContainer
        item
        justify={"center"}
        className={` ${classes.mlAuto} ${classes.mrAuto} ${
          classes.rotatingCardContainer
        } ${enableRotate ? classes.manualRotate : ``} ${
          enableRotate && this.state.activateRotate
            ? classes.activateRotate
            : ``
        } `}
        md={12}
        xs={12}
        sm={12}
      >
        <Card
          className={`${classes.cardSize} ${
            enableRotate ? classes.cardRotate : ``
          }`}
        >
          <div
            className={`${classes.front}`}
            style={{ borderRadius: 15 }}
            onClick={() =>
              this.setState({ activateRotate: !this.state.activateRotate })
            }
          >
            <CardBody
              color={true}
              cardColor="primary"
              className={`${classes.cardBodySize} ${classes.cardBodyInverted}`}
              style={{
                color: whiteColor,
                backgroundColor: primaryColor[0],
                backgroundImage: `linear-gradient(to right, rgba(53, 72, 138, 0.9), rgba(53, 72, 138, 0.9)), url(${withPrefix(
                  "/star_inverted_white.png"
                )})`,
                border: `${viewMoreDetails ? `1px solid white` : ``}`,
                boxShadow: `${
                  viewMoreDetails
                    ? `0 2px 2px 0 rgb(255, 255, 255), 0 3px 1px -2px rgba(255,255,255), 0 1px 5px 0 rgb(255,255,255)`
                    : ``
                }`,
              }}
            >
              <GridContainer>
                <GridItem md={6} lg={6} xs={6} sm={6}>
                  <span className={classes.headerDescription}>
                    {headerDescription}
                  </span>
                  <h5 className={classes.headerTitle}>{headerTitle}</h5>
                </GridItem>
                <GridItem md={6} lg={6} xs={6} sm={6}>
                  <img
                    className={classes.headerLogo}
                    src={withPrefix("/star_logo_white.png")}
                    alt=""
                  ></img>
                </GridItem>
                <GridItem md={12} lg={12} xs={12} sm={12}>
                  <h5 className={classes.body}>{body}</h5>
                </GridItem>
                <GridItem md={12} lg={12} xs={12} sm={12}>
                  <GridContainer>
                    <GridItem md={9} lg={9} xs={9} sm={9}>
                      <GridContainer>
                        <GridItem
                          md={6}
                          lg={6}
                          xs={6}
                          sm={6}
                          className={classes.dateLineHeight}
                        >
                          <span className={classes.dateLabel}>
                            Created Date
                          </span>
                          <span className={classes.date}>
                            {formatMonth(createdDate)}
                          </span>
                        </GridItem>
                        <GridItem
                          md={6}
                          lg={6}
                          xs={6}
                          sm={6}
                          className={classes.dateLineHeight}
                        >
                          <span className={classes.dateLabel}>Expiry Date</span>
                          <span className={classes.date}>
                            {formatMonth(expiryDate)}
                          </span>
                        </GridItem>
                        <GridItem md={12} lg={12} xs={12} sm={12}>
                          <h5 className={classes.name}>{name}</h5>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    {emiPolicy && (
                      <GridItem md={3} lg={3} xs={3} sm={3}>
                        <h5
                          className={classes.name}
                          style={{
                            float: "right",
                            paddingTop: "40px",
                            fontSize: "26px",
                          }}
                        >
                          {"EMI"}
                        </h5>
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {viewMoreDetails && (
                <div
                  style={{
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: `auto`,
                    marginRight: `auto`,
                    display: `table`,
                  }}
                >
                  <img
                    src={withPrefix(`/tap.svg`)}
                    className={classes.tapIcon}
                    alt="qr code icon"
                  />
                  <div
                    className={classes.qrText}
                    style={{ fontStyle: "italic" }}
                  >
                    Tap to view more details
                  </div>
                </div>
              )}
            </CardBody>
          </div>
          <div
            className={classes.back}
            style={{
              borderRadius: 15,
              display: enableRotate ? `contents` : `none`,
            }}
            onClick={() =>
              this.setState({ activateRotate: !this.state.activateRotate })
            }
          >
            <CardBody
              color={true}
              cardColor="primary"
              className={`${classes.cardBodySize}`}
              style={{
                color: whiteColor,
                backgroundColor: `${primaryColor[0]}`,
                backgroundImage: `linear-gradient(to right, rgba(53, 72, 138, 0.9), rgba(53, 72, 138, 0.9)), url(${withPrefix(
                  "/star_inverted_white.png"
                )})`,
              }}
            >
              <GridContainer>
                <GridItem
                  md={12}
                  lg={12}
                  xs={12}
                  sm={12}
                  style={{ textAlign: `left`, transform: `scale(-1,1)` }}
                >
                  <span
                    className={classes.headerDescription}
                  >{`People covered`}</span>
                </GridItem>
                {peopleCovered.map((insurer, index) => {
                  const partition =
                    peopleCovered.length > 6
                      ? peopleCovered.length > 12
                        ? 4
                        : 6
                      : 12;
                  return (
                    <GridItem
                      md={partition}
                      lg={partition}
                      xs={partition}
                      sm={partition}
                      style={{
                        marginLeft: `auto`,
                        textAlign: `left`,
                        lineHeight: `1.2`,
                        transform: `scale(-1,1)`,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "block",
                      }}
                      key={index}
                    >
                      <span
                        title={insurer.insurerName}
                        className={
                          peopleCovered.length <= 5
                            ? classes.peopleCoveredTitle
                            : classes.peopleCoveredTitlesix
                        }
                      >
                        {`${insurer.insurerName}`}
                      </span>
                      <span
                        title={insurer.insurerRelationshipName}
                        className={
                          peopleCovered.length <= 5
                            ? classes.headerDescription
                            : classes.headerDescriptionsix
                        }
                      >
                        {" "}
                        - {insurer.insurerRelationshipName}
                      </span>
                    </GridItem>
                  );
                })}

                {sumInsured && (
                  <GridItem
                    style={{
                      position: `absolute`,
                      bottom: peopleCovered.length <= 5 ? `8%` : "3%",
                      right: `0%`,
                      transform: `scale(-1,1)`,
                    }}
                  >
                    <GridContainer>
                      <GridItem
                        md={12}
                        lg={12}
                        xs={12}
                        sm={12}
                        className={`${classes.mlAuto} ${classes.mrAuto}`}
                      >
                        <CustomLinearProgress
                          variant="determinate"
                          value={100}
                          classes={{
                            root:
                              peopleCovered.length <= 5
                                ? classes.root
                                : classes.rootSixInsurer,
                            bar: classes.usedBar,
                          }}
                          style={{
                            width: "100%",
                            display: "inline-block",
                            borderRadius: `15px`,
                            color: "white",
                          }}
                        />
                      </GridItem>
                      <GridItem md={4} lg={4} sm={4} xs={4}>
                        <span className={classes.dateLabel}>
                          <span
                            className={classes.dot}
                            style={{ background: `white` }}
                          ></span>
                          <span>Sum Insured</span>
                          <span
                            className={classes.date}
                            style={{ lineHeight: 1.3 }}
                          >
                            {headerDescription &&
                            headerDescription.includes("Travel")
                              ? `$ ${this.applyCommasForAmount(sumInsured)}`
                              : `₹ ${this.applyCommasForAmount(sumInsured)}`}
                          </span>
                        </span>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </div>
        </Card>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.showModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose()}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.handleClose()}
              style={{
                color: `#000`,
                float: `right`,
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
        </Dialog>
      </GridContainer>
    );
  }
}

export default withStyles(policyCardStyles)(PolicyCard);
