import React from "react";
import PropTypes from "prop-types";
import { verifyCaptcha } from "util/ReCaptcha";
import { browserDocument, browserWindow } from "util/localStorageHelper";

const captcha_key = process.env.CAPTCHA_KEY;

class ReCaptcha extends React.Component {
  verifyCallback = async (response) => {
    if (process.env.BASE_URL === "http://localhost:8000/") {
      this.props.toggleCaptchaVerified(true);
    } else {
      const res = await verifyCaptcha(response);
      if (this.props.toggleCaptchaVerified) {
        if (res.success === true) {
          this.props.toggleCaptchaVerified(true);
        } else {
          this.props.toggleCaptchaVerified(false);
        }
      }
    }
  };
  onloadCallback = () => {
    try {
      if (browserDocument.getElementById("captcha-id")) {
        this.props.toggleCaptchaVerified(false);
        browserWindow.grecaptcha.render("captcha-id", {
          sitekey: captcha_key,
          callback: this.verifyCallback,
        });
      } else {
        setTimeout(() => {
          this.props.toggleCaptchaVerified(false);
          browserWindow.grecaptcha.render("captcha-id", {
            sitekey: captcha_key,
            callback: this.verifyCallback,
          });
        }, 5000);
      }
    } catch {
      if (
        !browserDocument.getElementById("captcha-id") ||
        browserDocument.getElementById("captcha-id").innerHTML === ""
      )
        this.props.toggleCaptchaVerified(true);
    }
  };

  componentDidMount() {
    if (browserWindow.grecaptcha && browserWindow.grecaptcha.render) {
      this.onloadCallback();
    } else {
      setTimeout(() => {
        if (browserWindow.grecaptcha && browserWindow.grecaptcha.render) {
          this.onloadCallback();
        }
      }, 5000);
      browserWindow.addEventListener(`load`, () => {
        if (browserDocument.readyState === `complete`) {
          if (browserWindow.grecaptcha && browserWindow.grecaptcha.render) {
            this.onloadCallback();
          } else {
            setTimeout(() => {
              if (browserWindow.grecaptcha && browserWindow.grecaptcha.render) {
                this.onloadCallback();
              }
            }, 5000);
          }
        }
      });
    }
  }

  render() {
    return (
      <div
        id="captcha-id"
        data-sitekey={captcha_key}
        style={{ margin: "0 auto", width: "304px" }}
      ></div>
    );
  }
}

ReCaptcha.propTypes = {
  toggleCaptchaVerified: PropTypes.func
}

export default ReCaptcha;
