
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

export async function verifyCaptcha(response) {
  const captchaUrl = `${CUSTOMER_PORTAL_API_URL}/cp/authentication/token/recaptcha`;
  return fetch(captchaUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      clientId: `7X2hEMm7YlhRXb7S3fjYs4GHXTeroWUr`,
    },
    body: JSON.stringify({
      response: response,
    }),
  })
    .then((res) => res.json())
    .catch((err) => {
      return err;
    });
}

// export const mapDispatchToProps = (dispatch) => ({
//   setCaptchaVerified: (flag) => {
//     dispatch(setCaptchaVerified(flag));
//   },
// });

// export const mapStateToProps = (state) => ({
//   captchaVerified: state.captchaVerified,
// });
