import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GridItem from 'components/Grid/GridItem';
import { getPolicyDetails, ERROR_MESSAGES } from 'util/SelfService';
import PolicyDetails from './PolicyDetails';
import Loading from 'components/Loading/Loading';

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
const APP_VERSION = process.env.APP_VERSION;

class DownloadDocs extends React.Component {
  state = {
    policies: [],
    errorMessage: ERROR_MESSAGES.default,
    loading: true,
    policy_token: ''
  };

  getPolicyDetails = (url, body) => {
    const { basicDetails } = this.props;
    let options = {
      method: 'GET',
      headers: {
        clientId: `WEB`
      }
    };
    if(url.includes('validate')){
      options.method = 'POST'
      options.headers = {
        clientId: 'PORTAL',
        'content-type': 'application/json',
        appVersion: APP_VERSION,
      }
      options.body = JSON.stringify(body)
    }
    if (basicDetails.mobileNoToken)
      options.headers.Authorization = `Bearer ${basicDetails.mobileNoToken}`;
    fetch(url, options)
      .then(res => res.json())
      .then(response => {
        if (basicDetails.policyNo === ``) {
          response = response.map(getPolicyDetails);
        } else {
          response = [getPolicyDetails(response)];
        }
        this.setState({
          policies: response,
          loading: false,
          policy_token: basicDetails.mobileNoToken
        });
      })
      .catch(() => {
        this.setState({
          policies: [],
          errorMessage: ERROR_MESSAGES.network_problem,
          loading: false
        });
      });
  };

  componentWillMount = () => {
    const { basicDetails } = this.props;
    let body = {}
    let getPoliciesAPI = `${CUSTOMER_PORTAL_API_URL}/cp/policy/user/`;
    if (basicDetails.policyNo === ``) {
      getPoliciesAPI += `get/policies`;
    } else {
      getPoliciesAPI += `policy/validate`;
      body.policyNumber= basicDetails.policyNo,
      body.dob= basicDetails.dob,
      body.appVersion=APP_VERSION
    }
     this.getPolicyDetails(getPoliciesAPI, body);
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.loading ? (
          <Loading
            classes={classNames(
              classes.mrAuto,
              classes.mlAuto,
              classes.loading
            )}
          />
        ) : this.state.policies.length !== 0 ? (
          this.state.policies.map(policy => {
            if (policy.displayCard) {
              return (
                <PolicyDetails
                  policy={policy}
                  classes={classes}
                  currentStage={this.props.currentStage}
                  policyToken={this.state.policy_token}
                />
              );
            } else {
              return (
                <GridItem
                  className={classes.noPolicies}
                  md={12}
                  sm={12}
                  xs={12}>
                  <h4 className={classNames(classes.title, classes.textCenter)}>
                    {ERROR_MESSAGES.no_policies}
                  </h4>
                </GridItem>
              );
            }
          })
        ) : (
          <GridItem className={classes.noPolicies} md={12} sm={12} xs={12}>
            <h4 className={classNames(classes.title, classes.textCenter)}>
              {this.state.errorMessage.length !== 0
                ? this.state.errorMessage
                : ERROR_MESSAGES.no_policies}
            </h4>
          </GridItem>
        )}
      </>
    );
  }
}

DownloadDocs.propTypes = {
  classes: PropTypes.object,
  basicDetails: PropTypes.object,
  currentStage: PropTypes.string
};

export default DownloadDocs;