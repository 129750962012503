import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.jsx";
import classNames from "classnames";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
  phone_no_regex,
  policy_no_regex,
  MOBILE,
  POLICY_NO,
  STAGES,
  BasicInitialState,
  ERROR_MESSAGES,
  dateSeperator,
  bancs_policy_no_regex
} from "util/SelfService";
import ReCaptcha from "ps-components/Captcha/ReCaptcha";
import Loading from "components/Loading/Loading";

const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;

class BasicDetails extends React.Component {
  state = BasicInitialState;

  toggleCaptchaVerified = (captchaVerified) => {
    this.setState({
      captchaVerified,
    });
  };

  handleChange = (event) => {
    let policy = event.target.value;
    switch (event.target.id) {
      case MOBILE.id:
        if (!isNaN(event.target.value) && event.target.value.length <= 10) {
          this.setState({ [event.target.id]: event.target.value });
        }
        break;
      case POLICY_NO.id:
        if (policy.substring(0,1).toUpperCase() =='P'){
        switch (policy.length) {
          case 1:
          case 8:
          case 11:
          case 16:
            policy = policy + `/`;
            break;
        }
        if (policy.length <= 23) {
          this.setState({ [event.target.id]: policy });
        }
      }
      else{
        if (policy.length <= 15) {
          this.setState({ [event.target.id]: policy });
        }
      }
    }
  };

  changeStage = (res) => {
    let mobileNo,uwType;
    if(res && res.mobileNo){
    mobileNo=res.mobileNo;
    }
    if(res && res.uwType){
      uwType=res.uwType;
      }
    let updateState = Object.assign({}, this.state);
    updateState.nextStage = this.state.nextStage;
    if (mobileNo != undefined) {
      updateState.mobile = mobileNo;
    }
    if (uwType != undefined) {
      updateState.uwType = uwType;
      this.setState({
        uwType:uwType
      })
    }
    updateState.currentStage = STAGES.OTP;
    delete updateState.captchaVerified;
    updateState.sendmailFirstStage = '';
    this.props.updateSelfServiceState(updateState);
  };

  setErrorMessage = (errorMessage) => {
    this.setState({
      errorMessage,
      loading: false
    })
  }

  sendOTP = (nextStage) => {
    let otpAPI = `${CUSTOMER_PORTAL_API_URL}/cp/authentication/token/generateOtp`;
    let existsCheck = `${CUSTOMER_PORTAL_API_URL}/cp/policy/user/check/policy/exists`;
    if (this.state.mobile !== ``) {
      otpAPI += `?mobileNo=${this.state.mobile}&serviceType=prelogin`;
    } else {
      existsCheck=existsCheck+`?PolicyNumber=${this.state.policyNo}&DOB=${this.state.dob}&source=selfservice`;
      otpAPI += `?policyno=${this.state.policyNo}&DOB=${this.state.dob}&serviceType=prelogin`;
    }
    this.setState(
      {loading:true,nextStage},() => {
    fetch(this.state.mobile !== `` ? otpAPI : existsCheck)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (this.state.policyNo != BasicInitialState.policyNo && res) {
              if(this.state.mobile == ``){
                if(res.uwType){
                otpAPI=otpAPI+`&uwType=${res.uwType}`
                }
              fetch(otpAPI)
                .then((res1) => {
                  if (res1.status === 200) {
                    res1.json().then((res1) => {
                      if (this.state.policyNo != BasicInitialState.policyNo) {
                        if(res.uwType){
                          res1.uwType=res.uwType
                          }
                        this.changeStage(res1);
                      }
                    }
                    )
                  }
                      else {
                        if (this.state.mobile !== BasicInitialState.mobile) {
                          this.setErrorMessage(ERROR_MESSAGES.invalid_mobile);
                        } else {
                          this.setErrorMessage(ERROR_MESSAGES.invalid_policy_dob)
                        }
                      }
                    }).catch(() => {
                      this.setErrorMessage(ERROR_MESSAGES.network_problem)
                    });
                    }
            } else {
              this.changeStage();
            }
          });
        } else {
          if (this.state.mobile !== BasicInitialState.mobile) {
            this.setErrorMessage(ERROR_MESSAGES.invalid_mobile);
          } else {
            this.setErrorMessage(ERROR_MESSAGES.invalid_policy_dob)
          }
        }
      })
      .catch(() => {
        this.setErrorMessage(ERROR_MESSAGES.network_problem)
      });
    })
  };

  sendEmail = (SEND_EMAIL) => {
    let updateState = Object.assign({}, this.state);
    updateState.sendmailFirstStage = SEND_EMAIL;
    this.props.updateSelfServiceState(updateState);
    this.sendOTP(SEND_EMAIL)
  };

  disableButton = () =>
    (this.state.mobile.length === 0 &&
      (this.state.policyNo.length === 0 || this.state.dob === ``)) ||
    !this.state.captchaVerified;

  handleDate = (value) => {
    switch (typeof value) {
      case `string`:
        if(value.search(`^[0-9-]*$`) === 0){
        if(this.state.dob !== value+dateSeperator){
        if(!value.endsWith(dateSeperator)){
        switch (value.length) {
          case 2:
          case 5:
            value = value + dateSeperator;
            break;
        }
        }else{
          value = value.substr(0,value.length-1);
        }
        }
        if (value.length <= 10) {
          this.setState({ dob: value });
        }
        }
        break;
      case `object`:
        this.setState({ dob: value.format(`DD-MM-YYYY`) });
        break;
    }
  };

  isDateValid = (dob) => {
    if(dob.length === 0){
      return true;
    }
    if(dob.length < 10){
      return false;
    }
    else{
      dob = dob.split(`-`);
      let temp = dob[0];
      dob[0] = dob[1];
      dob[1] = temp;
      dob = new Date(dob);
      let current = new Date();
      if(dob < current){
        return true;
      }
      else{
        return false;
      }
    }
  }

  render() {
    const { classes } = this.props;
    if(this.state.loading){
      return  (
        <Loading classes={classNames(classes.mrAuto, classes.mlAuto,classes.loading)} />
      )
    }
    else{
    return (
      <>
        <GridItem
          md={9}
          sm={9}
          xs={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <CustomInput
            labelText={MOBILE.label}
            disabled={
              this.state.policyNo.length !== 0 || this.state.dob.length !== 0
            }
            id={MOBILE.id}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{}}
            onChange={this.handleChange}
            value={this.state.mobile}
            error={
              this.state.mobile.length != 0 &&
              this.state.mobile.search(phone_no_regex) !== 0
            }
          />
        </GridItem>
        <GridItem
          md={9}
          sm={9}
          xs={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h4 className={classes.textCenter}>Or</h4>
        </GridItem>
        <GridItem
          style={{
            display: `inline-flex`,
          }}
          md={9}
          sm={9}
          xs={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <GridItem style={{paddingLeft:`0px`}} md={6}>
            <CustomInput
              labelText={POLICY_NO.label}
              id={POLICY_NO.id}
              disabled={this.state.mobile.length !== 0}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{}}
              onChange={this.handleChange}
              value={this.state.policyNo}
              error={
                this.state.policyNo.length != 0 &&
                (this.state.policyNo.search(policy_no_regex) !== 0 && this.state.policyNo.search(bancs_policy_no_regex) !== 0)
              }
            />
          </GridItem>
          <GridItem style={{paddingRight : `0px`}} md={6}>
            <FormControl fullWidth className={classes.dateFeild}>
              <Datetime
                timeFormat={false}
                id="self_service_dob"
                labelText="Enter Insured Date of Birth *"
                inputProps={{
                  placeholder: `Enter Insured Date of Birth *`,
                  readOnly: false,
                  disabled: this.state.mobile.length !== 0,
                  value: this.state.dob,
                }}
                isValidDate={function(current) {
                  return current.isBefore(new Date());
                }}
                onChange={this.handleDate}
                value={this.state.dob}
                closeOnSelect={true}
              />
              {
                !this.isDateValid(this.state.dob) &&
                <FormHelperText id="date-error" error>{ERROR_MESSAGES.invalid_date}</FormHelperText>
                }
            </FormControl>
          </GridItem>
        </GridItem>
        {this.state.errorMessage.length != 0 &&
        <GridItem className={classes.errorMessageStyle}>
          <FormHelperText id="error-helper" error>
            {this.state.errorMessage}
          </FormHelperText>
        </GridItem>
        }
        <ReCaptcha toggleCaptchaVerified={this.toggleCaptchaVerified} />
        <GridContainer
          md={11}
          sm={12}
          xs={12}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.basicButtons
          )}
        >
          <GridItem md={6} sm={6} xs={12}>
            <Button
              round
              disabled={this.disableButton()}
              onClick={() => this.sendOTP(STAGES.DOWNLOAD_DOCS)}
              variant="contained"
              color="primary"
            >
              Download documents now
            </Button>
          </GridItem>
          <GridItem md={6} sm={6} xs={12}>
            <Button
              round
              disabled={this.disableButton()}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.sendEmail(STAGES.SEND_EMAIL)}
            >
              Send documents to email
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );
    }
  }
}

BasicDetails.propTypes = {
  classes: PropTypes.object,
  updateSelfServiceState: PropTypes.func,
};

export default BasicDetails;
