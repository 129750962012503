import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import rotatingCardStyle from 'assets/jss/material-kit-pro-react/rotatingCards';
import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
  infoColor,
  whiteColor,
  roseColor,
  grayColor,
  hexToRgb,
} from 'assets/jss/material-kit-pro-react';

const genericCardStyle = (theme) => ({
  ...modalStyle,
  ...rotatingCardStyle,
  root: {
    height: `6px !important`,
    marginBottom: `-6px !important`,
  },
  rootSixInsurer: {
    height: `6px !important`,
    marginBottom: `-6px !important`,
    [theme.breakpoints.down('sm')]: {
      marginBottom: `-11px !important`,
    },
  },
  usedBar: {
    backgroundColor: `white !important`,
    height: `6px !important`,
  },
  remainingBar: {
    backgroundColor: `#58b879`,
    height: `8px`,
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  container,
  title,
  coloredShadow,
  cardTitle,
  mlAuto,
  mrAuto,
  description,
  blog: {
    padding: `50px 0`,
  },
  cardCategory: {
    marginBottom: `10px`,
    marginTop: `10px`,
    '& svg,& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: `relative`,
      top: `8px`,
      lineHeight: `0`,
    },
  },
  onHoverEffect: {
    '&:hover': {
      boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)!important`,
    },
  },
  description1: {
    ...description,
    lineHeight: `1.313rem`,
  },
  author: {
    display: `inline-flex`,
    '& a': {
      color: grayColor[1],
      textDecoration: `none`,
    },
  },
  card: {
    marginBottom: `80px`,
  },
  card4: {
    marginBottom: `60px`,
    textAlign: `center`,
    // boxShadow: "none"
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: `absolute !important`,
    bottom: `15px !important`,
    left: `15px !important`,
    color: `${whiteColor} !important`,
    fontSize: `1.125rem !important`,
    textShadow: `0 2px 5px rgba(${hexToRgb(grayColor[9])}, 0.5) !important`,
  },
  cardTitleWhite: {
    '&, & a': {
      ...title,
      marginTop: `.625rem`,
      marginBottom: `0`,
      minHeight: `auto`,
      color: `${whiteColor} !important`,
    },
  },
  cardCategorySocial: {
    marginTop: `10px`,
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      fontSize: `22px`,
      position: `relative`,
      marginTop: `-4px`,
      top: `2px`,
      marginRight: `5px`,
    },
    '& svg': {
      position: `relative`,
      top: `5px`,
    },
  },
  cardCategorySocialWhite: {
    marginTop: `10px`,
    color: `rgba(${hexToRgb(whiteColor)}, 0.8)`,
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      fontSize: `22px`,
      position: `relative`,
      marginTop: `-4px`,
      top: `2px`,
      marginRight: `5px`,
    },
    '& svg': {
      position: `relative`,
      top: `5px`,
    },
  },
  cardCategoryWhite: {
    marginTop: `10px`,
    color: `rgba(${hexToRgb(whiteColor)}, 0.7)`,
  },
  cardCategoryFullWhite: {
    marginTop: `10px`,
    color: whiteColor,
  },
  cardDescription: {
    ...description,
  },
  cardDescriptionWhite: {
    color: `rgba(${hexToRgb(whiteColor)}, 0.8)`,
  },
  authorWhite: {
    display: `inline-flex`,
    '& a': {
      color: `rgba(${hexToRgb(whiteColor)}, 0.8)`,
    },
  },
  avatar: {
    width: `30px`,
    height: `30px`,
    overflow: `hidden`,
    borderRadius: `50%`,
    marginRight: `5px`,
  },
  statsWhite: {
    color: `rgba(${hexToRgb(whiteColor)}, 0.8)`,
    display: `inline-flex`,
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      fontSize: `18px`,
      lineHeight: `18px`,
    },
    '& svg': {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      width: `18px`,
      height: `18px`,
    },
  },
  stats: {
    color: grayColor[0],
    display: `flex`,
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      fontSize: `18px`,
      lineHeight: `18px`,
    },
    '& svg': {
      position: `relative`,
      top: `3px`,
      marginRight: `3px`,
      marginLeft: `3px`,
      width: `18px`,
      height: `18px`,
    },
  },
  justifyContentCenter: {
    WebkitBoxPack: `center !important`,
    MsFlexPack: `center !important`,
    justifyContent: `center !important`,
  },
  textAlignCenter: {
    textAlign: `center !important`,
    alignItems: `center !important`,
  },
  textAlignLeft: {
    textAlign: `left !important`,
    alignItems: `left !important`,
  },
  iconWrapper: {
    color: `rgba(${hexToRgb(whiteColor)}, 0.76)`,
    margin: `10px auto 0`,
    width: `130px`,
    height: `130px`,
    border: `1px solid ${grayColor[14]}`,
    borderRadius: `50%`,
    lineHeight: `174px`,
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      fontSize: `55px`,
      lineHeight: `55px`,
    },
    '& svg': {
      width: `55px`,
      height: `55px`,
    },
  },
  iconWrapperColor: {
    borderColor: `rgba(${hexToRgb(whiteColor)}, 0.25)`,
  },
  iconWhite: {
    color: whiteColor,
  },
  iconRose: {
    color: roseColor[0],
  },
  iconInfo: {
    color: infoColor[0],
  },
  marginTop30: {
    marginTop: `30px`,
  },
  textCenter: {
    textAlign: `center`,
  },
  marginBottom20: {
    marginBottom: `20px`,
  },
  cardBody: {
    width: `100%`,
    '& h4': {
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      whiteSpace: `nowrap`,
    },
    '& div': {
      height: `52px`,
      display: `-webkit-box`,
      WebkitLineClamp: 2,
      WebkitBoxOrient: `vertical`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
    },
  },
  noWrap: {
    '& div': {
      display: `-webkit-box`,
      WebkitLineClamp: 6,
      WebkitBoxOrient: `vertical`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
    },
  },
  noMarginHeader: {
    marginTop: `30px !important`,
  },
  paddingBottom: {
    paddingBottom: `30px !important`,
  },
  noShadow: {
    boxShadow: `none !important`,
    border: `1px solid !important`,
    borderColor: `#eee !important`,
  },
  italic: {
    fontStyle: `italic`,
  },
  fixedHeight: {
    height: `200px`,
  },
  overlay: {
    height: `100%`,
    position: `absolute`,
    width: `100%`,
    backgroundColor: `rgba(0,0,0,0.6)`,
    zIndex: `99999`,
    borderRadius: `6px`,
    opacity: 0 /* make things invisible upon start */,
    animation: `fadeIn ease-in 1`,
    animationFillMode: `forwards`,
    animationDuration: `.7s`,
  },
  overlaySections: {
    position: `absolute`,
    top: `50%`,
    transform: `translate(-50%, -50%)`,
    msTransform: `translate(-50%, -50%)`,
    left: `50%`,
  },
  headerLogo: {
    float: `right`,
    height: 36,
    width: 84,
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 74,
    },
  },
  headerTitle: {
    textTransform: `uppercase`,
    marginTop: `-6px !important`,
    paddingTop: `0px !important`,
    fontWeight: 500,
    fontSize: 18,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: 15,
    },
  },
  headerDescription: {
    fontSize: 14,
    marginBottom: `0px !important`,
    paddingBottom: `0px !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  headerDescriptionsix: {
    fontSize: 13,
    marginBottom: `0px !important`,
    paddingBottom: `0px !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
  body: {
    fontSize: `26px`,
    fontWeight: 500,
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginBottom: 0,
    },
  },
  name: {
    textTransform: `uppercase`,
    fontSize: `18px`,
    fontWeight: 500,
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      marginTop: 10,
      marginBottom: 0,
    },
  },
  date: {
    display: `block`,
    fontSize: 18,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      marginTop: `-5px`,
    },
    '@media (max-width: 320px)': {
      fontSize: 12,
    },
  },
  dateLineHeight: {
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.5,
    },
  },
  dateLabel: {
    fontSize: `13px`,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: `10px`,
    },
  },
  cardSize: {
    borderRadius: `8px !important`,
    maxHeight: `220px !important`,
    maxWidth: `400px !important`,
    height: `220px !important`,
    width: `400px !important`,
    [theme.breakpoints.down('sm')]: {
      maxHeight: `182px !important`,
      maxWidth: `317px !important`,
      height: `182px !important`,
      width: `317px !important`,
    },
    '@media (max-width: 320px)': {
      marginTop: `60px !important`,
      maxHeight: `172px !important`,
      maxWidth: `300px !important`,
      height: `172px !important`,
      width: `300px !important`,
    },
  },
  cardBodyInverted: {
    backgroundPosition: '-10% !important',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40% 80%',
  },
  cardBodySize: {
    borderRadius: `8px !important`,
    padding: `22px !important`,
    maxHeight: `220px !important`,
    maxWidth: `400px !important`,
    height: `220px !important`,
    width: `400px !important`,
    backgroundPosition: '-10% !important',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40% 80%',
    float: 'right !important',
    [theme.breakpoints.down('sm')]: {
      maxHeight: `182px !important`,
      maxWidth: `317px !important`,
      height: `182px !important`,
      width: `317px !important`,
      padding: `16px !important`,
    },
    '@media (max-width: 320px)': {
      maxHeight: `172px !important`,
      maxWidth: `300px !important`,
      height: `172px !important`,
      width: `300px !important`,
    },
  },
  tapIcon: {
    height: 40,
    width: 40,
    display: `inline !important`,
    textAlign: 'center',
    // marginBottom: 10,
    // [theme.breakpoints.down('sm')]: {
    //   height: 27,
    //   width: 27,
    //   marginBottom: 5,
    //   marginRight: 10,
    //   marginLeft: `auto`,
    // },
  },
  qrIcon: {
    height: 40,
    width: 40,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: 27,
      width: 27,
      marginBottom: 5,
      marginRight: 10,
      marginLeft: `auto`,
    },
  },
  qrText: {
    color: 'white',
    // textTransform: 'Capitalize',
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  qrButton: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingRight: 10,
    },
  },
  dot: {
    height: `10px`,
    width: `10px`,
    marginRight: `5px`,
    [theme.breakpoints.down('sm')]: {
      marginRight: `3px`,
      height: `7px`,
      width: `7px`,
    },
    borderRadius: `50%`,
    display: `inline-block`,
  },
  linearProgress: {
    height: 16,
    [theme.breakpoints.down('sm')]: {
      height: 10,
    },
  },
  peopleCoveredTitle: {
    textTransform: `capitalize`,
    marginTop: `-6px !important`,
    paddingTop: `0px !important`,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: 13,
    },
  },
  peopleCoveredTitlesix: {
    textTransform: `capitalize`,
    marginTop: `-6px !important`,
    paddingTop: `0px !important`,
    fontWeight: 500,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: 13,
    },
  },
  rotateTransform: {
    transform: 'rotateY(180deg)',
  },
});

export default genericCardStyle;
