import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import headerLinksStyle from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx';
import ModalForm from 'ps-components/ModalForm/ModalForm';
import BasicDetails from './SelfServiceComponents/BasicDetails';
import DownloadDocs from './SelfServiceComponents/DownloadDocs';
import OTP from './SelfServiceComponents/OTP';
import { STAGES, SelfServiceInitialState } from 'util/SelfService';
import GridContainer from 'components/Grid/GridContainer';
import { browserDocument } from 'util/localStorageHelper';
import Button from "components/CustomButtons/Button.jsx";

class SelfService extends React.Component {
  state = {
    ...SelfServiceInitialState,
    openSelfService: this.props.openSelfService ? this.props.openSelfService : false
  };

  updateSelfServiceState = updateState => {
    this.setState({ ...updateState });
  };
  toggleSelfService = () => {
    if (this.state.openSelfService === false) {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById("mobileDrawer").style.zIndex = `1299`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `10`;
    } else {
      if (browserDocument.getElementById("mobileDrawer"))
        browserDocument.getElementById("mobileDrawer").style.zIndex = `9999999999`;
      if (browserDocument.getElementById("remy-thumbnail"))
        browserDocument.getElementById("remy-thumbnail").style.zIndex = `99999`;
    }
    if(this.props.openSelfService){
      this.props.toggleSelfService()
    }
    this.setState({
      openSelfService: !this.state.openSelfService,
    });
  };

  getStage = () => {
    const { classes } = this.props;
    const { currentStage } = this.state;
    switch (currentStage) {
      case STAGES.BASIC_DETAILS:
        return (
          <BasicDetails
            updateSelfServiceState={this.updateSelfServiceState}
            classes={classes}
          />
        );
      case STAGES.OTP:
        return (
          <OTP
            classes={classes}
            nextStage={this.state.nextStage}
            updateSelfServiceState={this.updateSelfServiceState}
            basicDetails={{
              mobile: this.state.mobile,
              policyNo: this.state.policyNo,
              dob: this.state.dob,
              uwType:this.state.uwType && this.state.uwType
            }}
          />
        );
      case STAGES.DOWNLOAD_DOCS:
      case STAGES.SEND_EMAIL:
        return (
          <DownloadDocs
            classes={classes}
            basicDetails={{
              mobile: this.state.mobile,
              policyNo: this.state.policyNo,
              dob: this.state.dob,
              mobileNoToken: this.state.mobileNoToken,
              uwType:this.state.uwType && this.state.uwType
            }}
            currentStage={this.state.currentStage}
          />
        );
    }
  };

  componentDidMount(){
    let recaptchaScript = browserDocument.createElement('script');
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js`;
    browserDocument.head.appendChild(recaptchaScript);
  }

  render() {
    let customModalStye = ``;
    let hideOverflowX = false;

    switch (this.state.currentStage) {
      case STAGES.DOWNLOAD_DOCS:
      case STAGES.SEND_EMAIL:
        customModalStye = `selfServPolicies`;
        hideOverflowX = true;
        break;
      default:
        break;
    }

    return (
      <>
      {this.props.customDescription  && (<div style={{ textAlign: "left",fontSize:'20px' }}>
          Download your policy document {" "}
          <Button
            onClick={this.toggleSelfService}
            round
            color="primary"
            style={{
              marginLeft: 20,
            }}
          >
            &nbsp; Click Here
          </Button>
        </div>)}
        {this.state.openSelfService && (
      <ModalForm
        customStyle={customModalStye}
        title={(this.state.sendmailFirstStage && this.state.sendmailFirstStage!='') ? this.state.sendmailFirstStage : this.state.currentStage}
        show={this.state.openSelfService}
        handleClose={this.toggleSelfService}
        showBackButton={
          this.state.currentStage !== STAGES.BASIC_DETAILS ? true : false
        }
        handleBackButton={() => {
          let updateState = Object.assign({}, this.state);
          updateState.currentStage = STAGES.BASIC_DETAILS;
          this.updateSelfServiceState(updateState);
        }}
        overFlowXHidden={hideOverflowX}>
        <GridContainer className={this.props.classes.selfServiceContainer}>
          {this.getStage()}
        </GridContainer>
      </ModalForm>
        )}
      </>
    );
  }
}

SelfService.propTypes = {
  classes: PropTypes.object,
  openSelfService: PropTypes.bool,
  toggleSelfService: PropTypes.func,
  customDescription: PropTypes.bool
};

export default withStyles(headerLinksStyle)(SelfService);
